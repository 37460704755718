import React from "react"
import { Helmet } from "react-helmet"
import Navbar from "./Navbar"
import Footer from "./Footer"

export default function Layout({ title, children }) {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <body className="dark:bg-neutral-900 overflow-x-hidden max-w-full"/>
            </Helmet>
            <Navbar />
            <div className="scroll-smooth dark:text-white">
                {children}
            </div>
            <Footer />
        </>
    )
}